function ExperienceSection() {
    return (
        <section class="o-section  t-section  ">

            <div class="o-section__header-bg  t-section__header"></div>
            <div class="o-section__content-bg  t-section__content"></div>

            <div class="o-container">
                <div class="o-section__container">

                    <header class="o-section__header  t-section__header">
                        <div class="o-content">
                            <h2 class="o-section__heading">
                                Professional experience
                            </h2>
                        </div>
                    </header>

                    <div class="o-section__content  t-section__content  u-pb-0">

                        <div class="a-experience-timeline  c-timeline  t-border-color">

                            <div class="c-timeline__item">
                                <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                <div class="o-content">
                                    <div class="o-grid">
                                        <div class="o-grid__col-md-5">
                                            <div class="c-work__timeframe">
                                                04/2024 &ndash; today
                                            </div>
                                            <h3 class="c-work__heading">
                                                <a href="https://www.proaurum.de" target="_blank" rel="noreferrer">pro aurum GmbH</a>
                                            </h3>
                                            <h4 class="c-work__title">
                                                Head of IT
                                            </h4>
                                            <div class="c-work__location">
                                                Munich, DE
                                            </div>
                                        </div>
                                        <div class="o-grid__col-md-7">
                                            <h3 class="c-experience_heading">Tasks and responsibilities</h3>
                                            <ul style={{paddingLeft: '15px', marginLeft: '0px'}}>
                                                <li>Funcational and disciplinary management of the IT department</li>
                                                <li>Reorganization and optimization of internal team structures and processes</li>
                                                <li>Selection and coordination of collaboration with external partners and service providers</li>
                                                <li>Implementation of requirements and budget planning for the IT department</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="c-timeline__item">
                                <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                <div class="o-content">
                                    <div class="o-grid">
                                        <div class="o-grid__col-md-5">
                                            <div class="c-work__timeframe">
                                                04/2023 &ndash; 03/2024
                                            </div>
                                            <h3 class="c-work__heading">
                                                <a href="https://www.schweber.net" target="_blank" rel="noreferrer">Career break</a>
                                            </h3>
                                        </div>
                                        <div class="o-grid__col-md-7">
                                            <p style={{paddingTop: '25px'}}>
                                                Multi-month motorcycle trip through the USA and Canada
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="c-timeline__item">
                                <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                <div class="o-content">
                                    <div class="o-grid">
                                        <div class="o-grid__col-md-5">
                                            <div class="c-work__timeframe">
                                                06/2020 &ndash; 03/2023
                                            </div>
                                            <h3 class="c-work__heading">
                                                <a href="https://www.occhio.com" target="_blank" rel="noreferrer">Occhio GmbH</a>
                                            </h3>
                                            <h4 class="c-work__title">
                                                Director IT
                                            </h4>
                                            <div class="c-work__location">
                                                Munich, DE
                                            </div>
                                        </div>
                                        <div class="o-grid__col-md-7">
                                            <h3 class="c-experience_heading">Tasks and responsibilities</h3>
                                            <ul style={{paddingLeft: '15px', marginLeft: '0px'}}>
                                                <li>Functional and disciplinary management of the IT department</li>
                                                <li>Implementation of requirements and budget planning for the IT department</li>
                                                <li>Selection and coordination of collaboration with external partners and service providers</li>
                                                <li>Designing and implementing a forward-looking, cloud-based IT strategy to support the entire value chain</li>
                                                <li>Responsibility for the digitalization of all business processes as part of a comprehensive transformation program</li><li>Ensuring the smooth operation and security of the company's internal IT systems</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="c-timeline__item">
                                <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                <div class="o-content">
                                    <div class="o-grid">
                                        <div class="o-grid__col-md-5">
                                            <div class="c-work__timeframe">
                                                10/2018 &ndash; 05/2020
                                            </div>
                                            <h3 class="c-work__heading">
                                                <a href="https://www.occhio.com" target="_blank" rel="noreferrer">Occhio GmbH</a>
                                            </h3>
                                            <h4 class="c-work__title">
                                                Head of Production Applications
                                            </h4>
                                            <div class="c-work__location">
                                                Munich, DE
                                            </div>
                                        </div>
                                        <div class="o-grid__col-md-7">
                                            <h3 class="c-experience_heading"p>Tasks and responsibilities</h3>
                                            <ul style={{paddingLeft: '15px', marginLeft: '0px'}}>
                                                <li>Conception of a long-term sustainable system landscape for the core systems ERP, CRM & BI, taking into account technical requirements</li>
                                                <li>Planning and implementation of migration projects to replace various legacy systems</li>
                                                <li>Design and implementation of a CI/CD environment for internally developed software solutions</li>
                                                <li>Design and implementation of central ticketing, monitoring and logging systems to ensure the smooth operation of the core systems</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="c-timeline__item">
                                <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                <div class="o-content">
                                    <div class="o-grid">
                                        <div class="o-grid__col-md-5">
                                            <div class="c-work__timeframe">
                                                07/2017 &ndash; 09/2018
                                            </div>
                                            <h3 class="c-work__heading">
                                                <a href="https://www.allianz.de" target="_blank" rel="noreferrer">Allianz Deutschland AG</a>
                                            </h3>
                                            <h4 class="c-work__title">
                                                Project manager ABS commission area
                                            </h4>
                                            <div class="c-work__location">
                                                Munich, DE
                                            </div>
                                        </div>
                                        <div class="o-grid__col-md-7">
                                            <h3 class="c-experience_heading">Tasks and responsibilities</h3>
                                            <ul style={{paddingLeft: '15px', marginLeft: '0px'}}>
                                                <li>Cross-divisional and cross-team planning, coordination and management of topics, budget and capacity for the further development of the core insurance system</li>
                                                <li>Regular coordination with program management, as well as program wide release and test management</li>
                                                <li>Design and coordination of processes and tools for a new cooperation model between BO and IT in the area of ABS commission</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="c-timeline__item">
                                <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                <div class="o-content">
                                    <div class="o-grid">
                                        <div class="o-grid__col-md-5">
                                            <div class="c-work__timeframe">
                                                10/2012 &ndash; 06/2017
                                            </div>
                                            <h3 class="c-work__heading">
                                                <a href="https://www.xenium.de" target="_blank" rel="noreferrer">Xenium AG</a>
                                            </h3>
                                            <h4 class="c-work__title">
                                                IT consultant
                                            </h4>
                                            <div class="c-work__location">
                                                Munich, DE
                                            </div>
                                        </div>
                                        <div class="o-grid__col-md-7">
                                            <h3 class="c-experience_heading">Projects and tasks</h3>
                                            <h4>Medical technology manufacturer</h4>
                                            <p>Establishment of the internal project management department and management of various projects</p>
                                            <ul style={{paddingLeft: '15px', marginLeft: '0px'}}>
                                                <li>Introduction of methodology and standards for project management</li>
                                                <li>Support in personnel search and selection</li>
                                                <li>Management of internal development projects in the area of hardware and software development</li>
                                                <li>Leading the build and commissioning of a laboratory automation system in the UK</li>
                                            </ul>
                                            <br/>
                                            <h4>Online lottery broker</h4>
                                            <p>Managing the migration of an existing online platform from an external service provider to the internal IT organization</p>
                                            <ul style={{paddingLeft: '15px', marginLeft: '0px'}}>
                                                <li>Steering the design of the hardware to operate the platform</li>
                                                <li>Management of the external service providers during the construction and commissioning of the hardware platform</li>
                                                <li>Planning and controlling of all sub-activities in the course of the migration of the production environment</li>
                                            </ul>
                                            <br/>
                                            <h4>Automobile manufacturer</h4>
                                            <p>Implementation of the global used car system for an integrated sales workplace</p>
                                            <ul style={{paddingLeft: '15px', marginLeft: '0px'}}>
                                                <li>Coordination of business and IT units, as well as participating service providers</li>
                                                <li>Release management and preparation of technical change requests</li>
                                                <li>Technical advice to the pilot markets CH, UK and TR in the context of piloting and rollout</li>
                                                <li>Implementation support including test preparation and execution</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </section>

    );
}

export default ExperienceSection;