import './App.css';
import HeaderSection from "./components/HeaderSection";
import React from "react";
import IntroSection from "./components/IntroSection";
import ExperienceSection from "./components/ExperienceSection";
import EducationSection from "./components/EducationSection";
import CertificatesSection from "./components/CertificatesSection";
import LanguagesSection from "./components/LanguagesSection";
import FooterSection from "./components/FooterSection";
import {Analytics} from "@vercel/analytics/react";
import {SpeedInsights} from "@vercel/speed-insights/react";

function App() {
    return (
        <>
            <Analytics/>
            <SpeedInsights/>
            <HeaderSection/>
            <IntroSection/>
            <ExperienceSection/>
            <EducationSection/>
            <CertificatesSection/>
            <LanguagesSection/>
            <FooterSection/>
        </>
    );
}

export default App;
