function EducationSection() {
    return (
        <section className="o-section  t-section  ">

            <div className="o-section__header-bg  t-section__header"></div>
            <div className="o-section__content-bg  t-section__content"></div>

            <div className="o-container">
                <div className="o-section__container">

                    <header className="o-section__header  t-section__header">
                        <div className="o-content">
                            <h2 className="o-section__heading">Education</h2>
                        </div>
                    </header>

                    <div className="o-section__content  t-section__content  u-pt-0">

                        <div className="o-content">
                            <div
                                className="a-education-timeline  c-timeline  t-border-color  o-section__full-top-space">
                                <div className="c-timeline__end  t-border-color"></div>

                                <div className="c-timeline__item">
                                    <div className="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                    <div className="o-content">
                                        <div className="o-grid">
                                            <div className="o-grid__col-md-5">
                                                <div className="c-work__timeframe">
                                                    04/2010 &ndash; 09/2012
                                                </div>
                                                <h3 className="c-work__heading"><a href="https://www.tum.de"
                                                                                   target="_blank" rel="noreferrer">TU Munich</a></h3>
                                                <div className="c-work__location">
                                                    Munich, DE
                                                </div>
                                            </div>
                                            <div className="o-grid__col-md-7">
                                                <p style={{paddingTop: '25px'}}>
                                                    Study of Information Systems<br/>
                                                    Degree: Master of Science<br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="c-timeline__item">
                                    <div className="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                    <div className="o-content">
                                        <div className="o-grid">
                                            <div className="o-grid__col-md-5">
                                                <div className="c-work__timeframe">
                                                    10/2005 &ndash; 02/2010
                                                </div>
                                                <h3 className="c-work__heading">
                                                    <a href="https://www.uni-due.de" target="_blank" rel="noreferrer">University of
                                                        Duisburg-Essen</a>
                                                </h3>
                                                <div className="c-work__location">
                                                    Essen, DE
                                                </div>
                                            </div>
                                            <div className="o-grid__col-md-7">
                                                <p style={{paddingTop: '25px'}}>
                                                    Study of Information Systems<br/>
                                                    Degree: Bachelor of Science<br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </section>
    );
}

export default EducationSection;