function HeaderSection() {
    return (
        <section class="o-section  t-section  ">

            <div class="o-section__header-bg  t-section__header"></div>
            <div class="o-section__content-bg  t-section__content"></div>

            <div class="o-container">
                <div class="o-section__container">

                    <header class="o-section__header  t-section__header">
                        <div class="o-content">
                            <h2 class="o-section__heading">
                                Intro
                            </h2>
                        </div>
                    </header>

                    <div class="o-section__content  t-section__content  ">

                        <div class="o-content">
                            <div class="c-intro">
                                <div class="o-content__body">
                                    <p>I am a committed, creative and team-oriented IT manager who enjoys taking on new
                                        challenges and developing myself constantly. My goal is to create innovative and
                                        sustainable IT solutions that offer added value for customers and society. In my
                                        more than 10 years of professional career in the IT industry, I have acquired a
                                        very broad and deep knowledge in various disciplines.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </section>
    )
}

export default HeaderSection;