function FooterSection() {
    return (
        <section className="o-section  t-section  o-section--footer">

            <div className="o-section__header-bg  t-section__header"></div>
            <div className="o-section__content-bg  t-section__content"></div>

            <div className="o-container">
                <div className="o-section__container">

                    <header className="o-section__header  t-section__header">
                        <div className="o-content">
                            <h2 className="o-section__heading">Contact</h2>
                        </div>
                    </header>

                    <div className="o-section__content  t-section__content  ">

                        <div className="c-footer__contact">
                            <div className="o-grid">

                                <div className="o-grid__col-md-3  o-grid__col-sm-6">
                                    <div className="o-content">
                                        <div className="o-content__body">
                                            <h4>Location</h4>
                                            <address>
                                                Munich, DE
                                            </address>
                                        </div>
                                    </div>
                                </div>

                                <div className="o-grid__col-md-3  o-grid__col-sm-6">
                                    <div className="o-content">
                                        <div className="o-content__body">
                                            <h4>Phone</h4>
                                            <p>(+49) 160 740 1158</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="o-grid__col-md-3  o-grid__col-sm-6">
                                    <div className="o-content">
                                        <div className="o-content__body">
                                            <a href="https://www.michaelschaub.net" target="_blank" rel="noreferrer"
                                               className="t-link-container">
                                                <h4>Web</h4>
                                                <p>
                                                    <span className="t-link-container__item--blended">
                                                        www.michaelschaub.net
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="o-grid__col-md-3  o-grid__col-sm-6">
                                    <div className="o-content">
                                        <div className="o-content__body">
                                            <a href="mailto: info@michaelschaub.net" target="_blank" rel="noreferrer"
                                               className="t-link-container">
                                                <h4>Mail</h4>
                                                <p>
                                                    <span className="t-link-container__item--blended">
                                                        info@michaelschaub.net
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <hr className="c-footer__contact-divider"/>

                        <div className="o-content">
                            <div className="c-footer__bottom">
                                <div className="c-footer__brand">

                                    <div className="c-brand">
                                        <div className="o-content__body">
                                            <h1 className="c-brand__title  t-title">
                                                <span className="c-brand__sizer  c-brand__sizer--sm">
                                                    <span className="a-footer  c-brand__first-word  t-title__first-word">
                                                        Michael
                                                    </span>
                                                    <span className="a-footer  c-brand__second-word  t-title__second-word">
                                                        Schaub
                                                    </span>
                                              </span>
                                            </h1>
                                        </div>
                                    </div>

                                </div>

                                <ul className="c-footer__social-buttons  c-social-buttons  o-content__body">
                                    <li className="a-footer">
                                        <a href="https://www.xing.com/profile/Michael_Schaub10/cv" target="_blank"
                                           rel="noreferrer" className="c-social-button  t-social-button">
                                            <i className="fab  fa-lg  fa-xing"></i>
                                        </a>
                                    </li>
                                    <li className="a-footer">
                                        <a href="https://www.linkedin.com/in/michael-schaub-a60a7928/" target="_blank"
                                           rel="noreferrer" className="c-social-button  t-social-button">
                                            <i className="fab  fa-lg  fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </section>
    );
}

export default FooterSection;