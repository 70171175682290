function EducationSection() {
    return (
        <section className="o-section  t-section  ">

            <div className="o-section__header-bg  t-section__header"></div>
            <div className="o-section__content-bg  t-section__content"></div>

            <div className="o-container">
                <div className="o-section__container">

                    <header className="o-section__header  t-section__header">
                        <div className="o-content">
                            <h2 className="o-section__heading">
                                Certificates
                            </h2>
                        </div>
                    </header>

                    <div className="o-section__content  t-section__content  ">

                        <div className="o-grid">

                            <div className="o-grid__col-xl-12  o-grid__col-sm-12">
                                <div className="o-content">
                                    <div className="o-media  o-media--block">
                                        <div className="o-media__figure">
                                            <div className="c-profile__icon">
                                                <i className="fas  fa-lg  fa-certificate"></i>
                                            </div>
                                        </div>
                                        <div className="o-media__body  o-content__body">
                                            <h3 className="t-primary-color">
                                                Systemic Coach (InKonstellation)
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="o-grid__col-xl-12  o-grid__col-sm-12">
                                <div className="o-content">
                                    <div className="o-media  o-media--block">
                                        <div className="o-media__figure">
                                            <div className="c-profile__icon">
                                                <i className="fas  fa-lg  fa-certificate"></i>
                                            </div>
                                        </div>
                                        <div className="o-media__body  o-content__body">
                                            <h3 className="t-primary-color">
                                                PRINCE2 - Practitioner Certification
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="o-grid__col-xl-12  o-grid__col-sm-12">
                                <div className="o-content">
                                    <div className="o-media  o-media--block">
                                        <div className="o-media__figure">
                                            <div className="c-profile__icon">
                                                <i className="fas  fa-lg  fa-certificate"></i>
                                            </div>
                                        </div>
                                        <div className="o-media__body  o-content__body">
                                            <h3 className="t-primary-color">
                                                PRINCE2 - Agile™ Practitioner Certificate in Agile Project Management
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="o-grid__col-xl-12  o-grid__col-sm-12">
                                <div className="o-content">
                                    <div className="o-media  o-media--block">
                                        <div className="o-media__figure">
                                            <div className="c-profile__icon">
                                                <i className="fas  fa-lg  fa-certificate"></i>
                                            </div>
                                        </div>
                                        <div className="o-media__body  o-content__body">
                                            <h3 className="t-primary-color">
                                                CPSA - Certified Professional for Software Architecture
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="o-grid__col-xl-12  o-grid__col-sm-12">
                                <div className="o-content">
                                    <div className="o-media  o-media--block">
                                        <div className="o-media__figure">
                                            <div className="c-profile__icon">
                                                <i className="fas  fa-lg  fa-certificate"></i>
                                            </div>
                                        </div>
                                        <div className="o-media__body  o-content__body">
                                            <h3 className="t-primary-color">
                                                CPRE - Certified Professional for Requirements Engineering
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </section>
    );
}

export default EducationSection;