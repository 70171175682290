function HeaderSection() {
    return (
        <section class="o-section o-section--header  t-section  t-section--header">
            <div class="c-header">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  c-header__header  t-section__header">
                            <div class="c-header__inner-header">

                                <div class="c-header__avatar">
                                    <div class="a-header  c-avatar">
                                        <img class="c-avatar__img" src="assets/images/header/michael_schaub.jpg" alt="Michael Schaub" />
                                    </div>
                                </div>

                            </div>
                        </header>

                        <div class="o-section__content  c-header__content  t-section__content">
                            <div class="c-header__inner-content">

                                <div class="c-header__top">

                                    <div class="c-header__brand">

                                        <div class="c-brand">
                                            <h1 class="c-brand__title  t-title">
                                                <span class="c-brand__sizer">
                                                    <span class="a-header  c-brand__first-word  t-title__first-word">Michael</span>
                                                    <span class="a-header  c-brand__second-word  t-title__second-word">Schaub</span>
                                                </span>
                                            </h1>
                                            <h2 class="a-header  c-brand__sub-title  t-sub-title">
                                                <span class="c-brand__sizer">IT Manager | Coach | Technology Enthusiast</span>
                                            </h2>
                                        </div>

                                    </div>

                                    <ul class="c-header__social-buttons  c-social-buttons">
                                        <li class="a-header">
                                            <a href="https://www.xing.com/profile/Michael_Schaub10/cv" target="_blank" rel="noreferrer" class="c-social-button  t-social-button">
                                                <i class="fab  fa-lg  fa-xing"></i>
                                            </a>
                                        </li>
                                        <li class="a-header">
                                            <a href="https://www.linkedin.com/in/michael-schaub-a60a7928/" target="_blank" rel="noreferrer" class="c-social-button  t-social-button">
                                                <i class="fab  fa-lg  fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>

                                </div>

                                <div class="c-header__contact">

                                    <hr class="a-header  c-header__contact-divider" />

                                    <div class="o-grid">

                                        <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                            <div class="a-header  o-content">
                                                <div class="o-content__body">
                                                    <h4>Location</h4>
                                                    <address>Munich, DE</address>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                            <div class="a-header  o-content">
                                                <div class="o-content__body">
                                                    <h4>Phone</h4>
                                                    <p>(+49) 160 740 1158</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                            <div class="a-header  o-content">
                                                <div class="o-content__body">
                                                    <a href="https://www.michaelschaub.net" target="_blank" rel="noreferrer" class="t-link-container">
                                                        <h4>Web</h4>
                                                        <p><span class="t-link-container__item--blended">www.michaelschaub.net</span></p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                            <div class="a-header  o-content">
                                                <div class="o-content__body">
                                                    <a href="mailto:info@michaelschaub.net" class="t-link-container">
                                                        <h4>Mail</h4>
                                                        <p><span class="t-link-container__item--blended">info@michaelschaub.net</span></p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    );
}

export default HeaderSection;