function LanguagesSection() {
    return (
        <section className="o-section  t-section  ">

            <div className="o-section__header-bg  t-section__header"></div>
            <div className="o-section__content-bg  t-section__content"></div>

            <div className="o-container">
                <div className="o-section__container">

                    <header className="o-section__header  t-section__header">
                        <div className="o-content">
                            <h2 className="o-section__heading">Languages</h2>
                        </div>
                    </header>

                    <div className="o-section__content  t-section__content  ">

                        <div className="o-grid">

                            <div className="o-grid__col-sm-6">
                                <div className="o-media  o-media--block  o-content">
                                    <div className="o-media__figure">
                                        <div className="c-number  t-primary-color">
                                            <i className="fas  fa-lg  fa-globe-europe"></i>
                                        </div>
                                    </div>
                                    <div className="o-media__body  o-content__body">
                                        <h3>German</h3>
                                        <p>Mother tongue</p>
                                    </div>
                                </div>
                            </div>

                            <div className="o-grid__col-sm-6">
                                <div className="o-media  o-media--block  o-content">
                                    <div className="o-media__figure">
                                        <div className="c-number  t-primary-color">
                                            <i className="fas  fa-lg  fa-globe-europe"></i>
                                        </div>
                                    </div>
                                    <div className="o-media__body  o-content__body">
                                        <h3>English</h3>
                                        <p>Business fluent</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>
    );
}

export default LanguagesSection;